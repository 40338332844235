import { useEffect, useState } from "react";
import {
  ServiceOfferingUsage,
  getServiceOfferingUsage,
  getAvailableHealthCheckCount,
} from "../services/core-api-adapter";

export default function useGetServiceOfferingUsage() {
  const [serviceOfferingUsageList, setServiceOfferingUsageList] = useState<
    ServiceOfferingUsage[]
  >([]);
  const [
    availableServiceOfferingUsageCount,
    setAvailableServiceOfferingUsageCount,
  ] = useState<number>(0);
  const [
    serviceOfferingUsageListFetchError,
    setServiceOfferingUsageListFetchError,
  ] = useState(false);
  const [
    isServiceOfferingUsageListLoading,
    setIsServiceOfferingUsageListLoading,
  ] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    setIsServiceOfferingUsageListLoading(true);

    getServiceOfferingUsage({ signal: controller.signal })
      .then((res) => {
        setAvailableServiceOfferingUsageCount(
          getAvailableHealthCheckCount(res.body)
        );

        setServiceOfferingUsageList(res.body);
        setServiceOfferingUsageListFetchError(false);
        setIsServiceOfferingUsageListLoading(false);
      })
      .catch(() => {
        if (controller.signal.aborted === false) {
          setServiceOfferingUsageListFetchError(true);
          setServiceOfferingUsageList([]);
          setIsServiceOfferingUsageListLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  return {
    serviceOfferingUsageList,
    serviceOfferingUsageListFetchError,
    isServiceOfferingUsageListLoading,
    availableServiceOfferingUsageCount,
  };
}
