import { Membership } from "../services/core-api-adapter";

export default function useGetProductType(membership: Membership | null) {
  function isPAYGProduct() {
    return membership?.productDetails?.code === "UNU01";
  }

  return {
    isPAYGProduct: isPAYGProduct(),
  };
}
