import { useQuery } from "@tanstack/react-query";
import { getProductList, Product } from "../services/core-api-adapter";

export default function useGetProductList() {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error } = useQuery({
    queryKey: ["getProductListData"],
    queryFn: async () => {
      const responseData = await getProductList({ signal: controller.signal });
      return responseData as Product[];
    },
  });

  return {
    products: data || [],
    productsFetchError: error || isError,
    isProductsLoading: isPending || isLoading,
  };
}
