import { useQuery } from "@tanstack/react-query";
import { getProductSelfServiceURL } from "../services/core-api-adapter";

interface UseGetProductSelfServiceURL {
  productID: string;
}

export default function useGetProductSelfServiceURL({
  productID,
}: UseGetProductSelfServiceURL) {
  const controller = new AbortController();

  const { data, isError, error } = useQuery({
    queryKey: ["getProductSelfServiceURLData"],
    queryFn: async () => {
      const responseData = await getProductSelfServiceURL(productID, {
        signal: controller.signal,
      });
      return responseData?.url as string | null;
    },
  });

  return {
    url: data,
    isError: Boolean(error || isError),
  };
}
