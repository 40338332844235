import { useQuery } from "@tanstack/react-query";
import { getProduct, Product } from "../services/core-api-adapter";

interface UseGetProductProps {
  productID: string;
}

export default function useGetProduct({ productID }: UseGetProductProps) {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error } = useQuery({
    queryKey: ["getMyBenefitsData"],
    queryFn: async () => {
      const responseData = getProduct(productID, {
        signal: controller.signal,
      });
      return responseData;
    },
  });

  return {
    product: data as Product | null,
    productFetchError: error || isError,
    isProductLoading: isPending || isLoading,
  };
}
