import { useQuery } from "@tanstack/react-query";
import { getMyBenefits, Product } from "../services/core-api-adapter";

export default function useGetMyBenefits() {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error } = useQuery({
    queryKey: ["getMyBenefitsData"],
    queryFn: async () => {
      const responseData = await getMyBenefits({ signal: controller.signal });
      return (responseData as Product[]) || [];
    },
  });

  return {
    products: data || [],
    productsFetchError: error || isError,
    isProductsLoading: isPending || isLoading,
  };
}
