import { useQuery } from "@tanstack/react-query";
import { Doctor, getDoctorList } from "../services/core-api-adapter";

export default function useGetDoctorList() {
  const controller = new AbortController();

  const { data, error, isError, isPending, isLoading } = useQuery({
    queryKey: ["getDoctorList"],
    queryFn: async () => {
      const responseData = await getDoctorList({ signal: controller.signal });
      return responseData as Doctor[];
    },
  });

  return {
    doctorList: data as Array<Doctor>,
    doctorListFetchError: error || isError,
    isDoctorListLoading: isPending || isLoading,
  };
}
