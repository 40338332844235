import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import monitor, {
  AlertData,
  DeviceOrientation,
  EnabledVitalSigns,
  FaceSessionOptions,
  HealthMonitorCodes,
  HealthMonitorSession,
  OfflineMeasurements,
  SessionState,
  VitalSigns,
  VitalSignsResults,
  Gender,
  ImageValidity,
} from "@binah/web-sdk";
import { InfoType, InfoData, DemographicLimits } from "../types";
import { useTranslation } from "react-i18next";
import { HealthCheckQuestionnaireData } from "../../core-api-adapter";
import { captureException } from "../../application-monitoring-adapter";
import { trackEvent } from "../../analytics-adapter";

const useMonitor = (
  video: MutableRefObject<HTMLVideoElement>,
  cameraId: string,
  processingTime: number,
  licenseKey: string,
  startMeasuring: boolean,
  healthCheckQuestionnaireData?: HealthCheckQuestionnaireData | null
) => {
  const { t } = useTranslation();
  const [session, setSession] = useState<HealthMonitorSession>();
  const [sessionState, setSessionState] = useState<SessionState>();
  const [isMonitorReady, setIsMonitorReady] = useState<boolean>();
  const [enabledVitalSigns, setEnabledVitalSigns] =
    useState<EnabledVitalSigns>();
  const [isScanComplete, setIsScanComplete] = useState(false);
  const [offlineMeasurements, setOfflineMeasurements] =
    useState<OfflineMeasurements>();
  const [vitalSigns, setVitalSigns] = useState<VitalSigns | null>();
  const [error, setError] = useState<AlertData>({ code: -1 });
  const [info, setInfo] = useState<InfoData>({ type: InfoType.NONE });
  const isDismissing = useRef<boolean>(false);

  const setInfoWithDismiss = useCallback(
    (info: InfoData, seconds?: number) => {
      if (!isDismissing.current) {
        setInfo(info);
        if (seconds) {
          isDismissing.current = true;
          setTimeout(() => {
            setInfo({ type: InfoType.NONE });
            isDismissing.current = false;
          }, seconds * 1000);
        }
      }
    },
    [InfoType, setInfo, info, isDismissing, isDismissing.current]
  );

  const updateVitalSigns = useCallback((vitalSigns: VitalSigns) => {
    setVitalSigns((prev) => ({
      ...prev,
      ...vitalSigns,
    }));
  }, []);

  const onVitalSign = useCallback((vitalSign: VitalSigns) => {
    updateVitalSigns(vitalSign);
  }, []);

  const onFinalResults = useCallback((vitalSignsResults: VitalSignsResults) => {
    updateVitalSigns(vitalSignsResults.results);
    setIsScanComplete(true);
  }, []);

  const onError = (errorData: AlertData) => {
    setError(errorData);
  };

  const onWarning = (warningData: AlertData) => {
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_WARNING
    ) {
      setVitalSigns(null);
    } else {
      setInfo({
        message: `Warning: ${warningData.code}`,
        type: InfoType.INSTRUCTION,
      });
    }
    trackEvent({
      event: "action.healthCheckFaceScanWarningEncountered",
      source: "Health check",
      warningMessage: `Warning: ${warningData.code}`,
    });
  };

  const onImageData = useCallback((imageValidity: ImageValidity) => {
    let message: string;
    if (imageValidity != ImageValidity.VALID) {
      switch (imageValidity) {
        case ImageValidity.INVALID_DEVICE_ORIENTATION:
          message = t(
            "BinahScanFlow.measurenow.warning.unsupportedOrientation"
          );
          break;
        case ImageValidity.TILTED_HEAD:
          message = t("BinahScanFlow.measurenow.warning.headTilted");
          break;
        case ImageValidity.UNEVEN_LIGHT:
          message = t("BinahScanFlow.measurenow.warning.unevenLighting");
          break;
        case ImageValidity.INVALID_ROI:
        default:
          message = t("BinahScanFlow.measurenow.warning.faceNotDetected");
      }
      const infoData: InfoData = {
        type: InfoType.INSTRUCTION,
        message: message,
      };

      setInfo(infoData);
      trackEvent({
        event: "action.healthCheckFaceScanWarningEncountered",
        source: "Health check",
        warningMessage: message,
      });
    } else {
      setInfoWithDismiss({ type: InfoType.NONE });
    }
  }, []);

  const onStateChange = useCallback((state: SessionState) => {
    setSessionState(state);
    if (state === SessionState.MEASURING) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback((vitalSigns: EnabledVitalSigns) => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback(
    (offlineMeasurements: OfflineMeasurements) => {
      setOfflineMeasurements(offlineMeasurements);
    },
    []
  );

  const onActivation = useCallback(() => {
    // the device has been activated with activationId
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await monitor.initialize({
          licenseKey,
          licenseInfo: {
            onEnabledVitalSigns,
            onOfflineMeasurement,
            onActivation,
          },
        });

        setIsMonitorReady(true);
        setError({ code: -1 });
      } catch (e: any) {
        captureException(e);
        console.error("Error initializing HealthMonitor", e);
        setIsMonitorReady(false);
        setError({ code: e.errorCode });
      }
    })();
  }, [licenseKey]);

  function getSubjectDemographicDataFromHealthCheckQuestionnaireData(
    healthCheckQuestionnaireData: HealthCheckQuestionnaireData
  ) {
    const subjectDemographicData: any = {};

    if (healthCheckQuestionnaireData) {
      if (healthCheckQuestionnaireData.dateOfBirth) {
        const demographicAge =
          new Date().getFullYear() -
          new Date(
            healthCheckQuestionnaireData.dateOfBirth.value
          ).getFullYear();

        if (
          demographicAge &&
          demographicAge > DemographicLimits.MIN_ALLOWED_AGE &&
          demographicAge < DemographicLimits.MAX_ALLOWED_AGE
        ) {
          subjectDemographicData.age = Number(demographicAge);
        }
      }

      if (healthCheckQuestionnaireData.sexAtBirth) {
        const demographicGender =
          healthCheckQuestionnaireData.sexAtBirth.value === "M"
            ? Gender.MALE
            : Gender.FEMALE;
        subjectDemographicData.gender = demographicGender;
      }

      if (healthCheckQuestionnaireData.weight) {
        const demographicWeight = Number(
          healthCheckQuestionnaireData.weight.value
        );
        if (
          demographicWeight > DemographicLimits.MIN_ALLOWED_WEIGHT &&
          demographicWeight < DemographicLimits.MAX_ALLOWED_WEIGHT
        ) {
          subjectDemographicData.weight = demographicWeight;
        }
      }
    }

    return subjectDemographicData;
  }

  useEffect(() => {
    (async () => {
      try {
        if (!isMonitorReady || !processingTime || !video.current) {
          return;
        }

        sessionState === SessionState.ACTIVE && session?.terminate();

        let subjectDemographicData: any = {};
        if (healthCheckQuestionnaireData) {
          subjectDemographicData =
            getSubjectDemographicDataFromHealthCheckQuestionnaireData(
              healthCheckQuestionnaireData
            );
        }

        const options: FaceSessionOptions = {
          input: video.current,
          cameraDeviceId: cameraId,
          processingTime,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
          orientation: DeviceOrientation.PORTRAIT,
          subjectDemographic: subjectDemographicData,
          onImageData,
        };

        const faceSession = await monitor.createFaceSession(options);

        console.log(`Session started`);
        setSession(faceSession);
        setError({ code: -1 });
      } catch (e: any) {
        setError({ code: e.errorCode });
        console.error("Error creating a session", e);
      }
    })();
  }, [processingTime, isMonitorReady, cameraId]);

  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === SessionState.ACTIVE) {
        session?.start();
        setError({ code: -1 });
      }
    } else {
      sessionState === SessionState.MEASURING && session?.stop();
    }
  }, [startMeasuring]);

  return {
    sessionState,
    vitalSigns,
    enabledVitalSigns,
    offlineMeasurements,
    error,
    info,
    isScanComplete,
  };
};

export default useMonitor;
