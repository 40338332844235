import { useEffect, useState } from "react";
import {
  getMemberships,
  MedicalServiceProviderType,
  Membership,
} from "../services/core-api-adapter";

export default function useGetMemberships() {
  const [memberships, setMemberships] = useState<Membership[]>([]);
  const [firstMembership, setFirstMembership] = useState<Membership>({
    members: [],
    productDetails: {
      ...({} as any),
    },
  });
  const [membershipsFetchError, setMembershipsFetchError] = useState(false);
  const [isMembershipsLoading, setIsMembershipsLoading] = useState(true);
  const [medicalAssistanceProvider, setMedicalAssistanceProvider] = useState(
    MedicalServiceProviderType.WHATSAPP
  );

  useEffect(() => {
    const controller = new AbortController();
    setIsMembershipsLoading(true);
    getMemberships({ signal: controller.signal })
      .then((res) => {
        const memberships = "memberships" in res ? res.memberships : [];
        const firstMembershipValue: any = memberships[0];

        setMemberships(memberships);
        setFirstMembership(firstMembershipValue);
        setMembershipsFetchError(false);
        setIsMembershipsLoading(false);

        setMedicalAssistanceProvider(
          firstMembershipValue?.productDetails?.medicalServiceProvider
        );
      })
      .catch(() => {
        if (controller.signal.aborted === false) {
          setMembershipsFetchError(true);
          setMemberships([]);
          setIsMembershipsLoading(false);
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  return {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
    medicalAssistanceProvider,
  };
}
