import {
  getMessages,
  MessagesSuccessResponse,
} from "../services/core-api-adapter";
import { useQuery } from "@tanstack/react-query";

export default function useGetMessages() {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["getMessagesData"],
    queryFn: async () => {
      const response = await getMessages({ signal: controller.signal });
      return response as MessagesSuccessResponse;
    },
  });

  return {
    messages: isLoading ? [] : data?.appNotifications,
    messagesCount: data?.total,
    unreadMessagesCount: data?.unreadTotal,
    messagesFetchError: isError || error,
    isMessagesLoading: isLoading || isPending,
    refetchMessages: refetch,
  };
}
