import { useQuery } from "@tanstack/react-query";
import {
  GetMemberConsultationRatesItem,
  GetMemberConsultationStatus,
  getMemberConsultationRates,
} from "../services/core-api-adapter";

export default function useGetMemberConsultationRates() {
  const controller = new AbortController();

  const { data, error, isError, isPending, isLoading } = useQuery({
    queryKey: ["getMemberConsultationRateData"],
    queryFn: async () => {
      const responseData = await getMemberConsultationRates(
        GetMemberConsultationStatus.ACTIVE,
        {
          signal: controller.signal,
        }
      );

      return responseData as GetMemberConsultationRatesItem[];
    },
  });

  return {
    isConsultationRatesLoading: isPending || isLoading,
    consultationRatesFetchError: error || isError,
    consultationRates: data || [],
  };
}
