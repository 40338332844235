import { useQuery } from "@tanstack/react-query";
import { getMessageDetails, Message } from "../services/core-api-adapter";

interface UseGetMessageDetailsProps {
  messageID: string;
}

export default function useGetMessageDetails({
  messageID,
}: UseGetMessageDetailsProps) {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error } = useQuery({
    queryKey: ["getMessageDetailsData"],
    queryFn: async () => {
      const responseData = await getMessageDetails(messageID, {
        signal: controller.signal,
      });

      return responseData as Message | null;
    },
  });

  return {
    message: data,
    messageFetchError: error || isError,
    isMessageLoading: isPending || isLoading,
  };
}
