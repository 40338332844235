import {
  DoctorOnDemandPrimarySymptom,
  DoctorOnDemandPrimarySymptomStatus,
  getDoctorOnDemandPrimarySymptoms,
} from "../services/core-api-adapter";
import { useQuery } from "@tanstack/react-query";

interface UseGetDoctorOnDemandPrimarySymptomsProps {
  status?: DoctorOnDemandPrimarySymptomStatus;
}

export default function useGetDoctorOnDemandPrimarySymptoms({ status = DoctorOnDemandPrimarySymptomStatus.ACTIVE  }: UseGetDoctorOnDemandPrimarySymptomsProps = {}) {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error } = useQuery({
    queryKey: ["getDoctorOnDemandPrimarySymptomsData"],
    queryFn: async () => {
      const response = await getDoctorOnDemandPrimarySymptoms(status, { signal: controller.signal });
      return response as DoctorOnDemandPrimarySymptom[];
    },
  });

  return {
    primarySymptoms: data || [],
    primarySymptomsFetchError: isError || error,
    isPrimarySymptomsLoading: isLoading || isPending,
  };
}
