import { useQuery } from "@tanstack/react-query";
import {
  GetMemberConsultationsItem,
  GetMemberConsultationStatus,
  getMemberConsultations,
} from "../services/core-api-adapter";

export default function useGetMemberConsultationList() {
  const controller = new AbortController();

  const { data, error, isError, isPending, isLoading } = useQuery({
    queryKey: ["getMemberConsultationListData"],
    queryFn: async () => {
      const responseData = await getMemberConsultations(
        GetMemberConsultationStatus.ACTIVE,
        {
          signal: controller.signal,
        }
      );

      return responseData as GetMemberConsultationsItem[];
    },
  });

  return {
    consultationList: data || [],
    isConsultationListLoading: isPending || isLoading,
    consultationListFetchError: error || isError,
  };
}
