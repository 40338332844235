import { useQuery } from "@tanstack/react-query";
import {
  GetMemberContactDetailsSuccessResponseData,
  getMemberContactDetails,
} from "../services/core-api-adapter";

export default function useGetMemberContactDetails() {
  const controller = new AbortController();

  const { data, error, isError, isPending, isLoading } = useQuery({
    queryKey: ["getMemberContactDetailsData"],
    queryFn: async () => {
      const responseData = await getMemberContactDetails({
        signal: controller.signal,
      });
      return responseData as GetMemberContactDetailsSuccessResponseData | null;
    },
  });

  return {
    memberContactDetails: data,
    memberContactDetailsFetchError: error || isError,
    isMemberContactDetailsLoading: isPending || isLoading,
  };
}
